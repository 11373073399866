import React, { useEffect, useMemo, useState } from 'react';
import {
    Center,
    chakra,
    CircularProgress,
    CircularProgressLabel,
    Flex,
    Heading,
} from '@chakra-ui/react';
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion';
import { v4 as uuid } from 'uuid';

const Box = chakra(motion.div, {
    shouldForwardProp: (key: string) =>
        isValidMotionProp(key) || key === 'children',
});

const getRandomElem = <T,>(arr: T[]): T => {
    const idx = Math.floor(Math.random() * arr.length);
    return arr[idx];
};

interface ItemConfig {
    side: 'left' | 'right';
    height: number;
    width: number;
}

interface SectionConfig {
    id: string;
    items: ItemConfig[];
    height: number;
}

const V_SPACE = 16;

const px = (n: number) => `${n}px`;

const getTranscriptSections = (n: number) => {
    const itemDimensions: Omit<ItemConfig, 'side'>[] = [
        { width: 400, height: 40 },
        { width: 400, height: 40 },
        { width: 400, height: 60 },
        { width: 300, height: 40 },
    ];

    const sectionSizeOptions = [3, 4, 5];
    const sectionSizes = Array.from({ length: n }, (_, i) =>
        getRandomElem(sectionSizeOptions),
    );

    // We want to make sure the total number of items is even
    let cntItems = sectionSizes.reduce((acc, s) => acc + s, 0);
    if (cntItems % 2) {
        cntItems -= sectionSizes[0];
        sectionSizes[0] = sectionSizes[0] % 2 ? 4 : 3;
        cntItems += sectionSizes[0];
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let availableItems = Array.from({ length: cntItems }, (_, i) => ({
        side: i % 2 ? 'left' : 'right',
        ...getRandomElem(itemDimensions),
    })) as ItemConfig[];

    const transcriptSections = [];
    while (availableItems.length > 0) {
        const sectionSize = sectionSizes.shift();
        const sectionItems = availableItems.slice(0, sectionSize);
        transcriptSections.push({
            id: uuid(),
            items: sectionItems,
            height:
                sectionItems.reduce((acc, current) => acc + current.height, 0) +
                (sectionItems.length - 1) * V_SPACE,
        });
        availableItems = availableItems.slice(sectionSize);
    }

    return transcriptSections;
};

const getTranscriptItems = (transcriptSections: SectionConfig[]): any[] => {
    const itemVariants = {
        show: {
            opacity: 1,
            y: 0,
        },
        hidden: {
            opacity: 0,
            y: 20,
        },
    };

    return transcriptSections
        .flatMap(v => v.items)
        .map(({ height, width, side }, i) => (
            <Box
                key={i}
                flex='0 0 auto'
                height={px(height)}
                width={px(width)}
                bgColor={side === 'left' ? 'gray.100' : 'black'}
                alignSelf={side === 'left' ? 'start' : 'end'}
                boxShadow='2px 5px 4px rgba(0,0,0, 0.2)'
                borderRadius={10}
                variants={itemVariants}
            />
        ));
};

const Transcript = ({
    transcriptSections,
    animate,
}: {
    transcriptSections: SectionConfig[];
    animate?: boolean;
}) => {
    const containerVariants = {
        show: {
            transition: {
                delayChildren: 0.7,
                staggerChildren: 0.85,
            },
        },
        hidden: {},
    };

    const transcriptItems = useMemo(
        () => getTranscriptItems(transcriptSections),
        [transcriptSections],
    );

    const animationProps = animate
        ? {
              variants: containerVariants,
              animate: 'show',
              initial: 'hidden',
          }
        : {};

    return (
        <Box
            display='flex'
            flexDirection='column'
            width={'550px'}
            rowGap={px(V_SPACE)}
            {...animationProps}
        >
            {transcriptItems}
        </Box>
    );
};

interface StepperProps {
    steps: number;
    currentStep: number;
}
const Stepper = ({ steps, currentStep }: StepperProps) => {
    const _currentStep = currentStep - 1;
    return (
        <Flex columnGap={10}>
            {Array.from({ length: steps }, (_, i) => (
                <CircularProgress
                    key={i}
                    isIndeterminate={_currentStep === i}
                    value={
                        _currentStep === i
                            ? undefined
                            : _currentStep > i
                            ? 100
                            : 0
                    }
                    color='black'
                >
                    <CircularProgressLabel fontSize='lg'>
                        {i + 1}
                    </CircularProgressLabel>
                </CircularProgress>
            ))}
        </Flex>
    );
};

const TranscriptSections = ({
    transcriptSections,
    onSectionAnimated,
}: {
    transcriptSections: SectionConfig[];
    onSectionAnimated: (sectionId: string) => void;
}) => {
    const getSectionVariant = (h: number) => ({
        show: { height: h, transition: { duration: 1.2, delay: 1 } },
    });

    return (
        // ml={10} is used to keep the transcript centered i.e. same position as if there was no green bar on the side
        <Flex flexDirection='column' rowGap={px(V_SPACE)} ml={10}>
            <AnimatePresence>
                {transcriptSections.map((section, index) => (
                    <Box
                        key={section.id}
                        flex='0 0 auto'
                        display='flex'
                        columnGap={7}
                        initial={{ y: 0, opacity: 1 }}
                        exit={{
                            y: -100,
                            opacity: 0,
                            transition: { duration: 0.6, type: 'spring' },
                        }}
                    >
                        <Transcript
                            transcriptSections={transcriptSections.slice(
                                index,
                                index + 1,
                            )}
                        />
                        <Box
                            flex='0 0 auto'
                            width='12px'
                            bgColor='green.500'
                            variants={getSectionVariant(section.height)}
                            initial={{ height: 0 }}
                            animate={index === 0 ? 'show' : undefined}
                            onAnimationComplete={() =>
                                onSectionAnimated(section.id)
                            }
                        />
                    </Box>
                ))}
            </AnimatePresence>
        </Flex>
    );
};

const LoadingAnimation = () => {
    const [step, setStep] = useState<1 | 2>(1);
    const [transcriptSections, setTranscriptSections] = useState(() =>
        getTranscriptSections(2),
    );

    useEffect(() => {
        const timeoutId = setTimeout(() => setStep(2), 9000);
        return () => clearTimeout(timeoutId);
    }, []);

    return (
        <Center flexDirection='column' gap={8}>
            <Flex alignItems='center' gap={10}>
                {step === 1 && (
                    <Heading size='lg'>Analyzing conversation</Heading>
                )}
                {step === 2 && (
                    <Heading size='lg'>Preparing feedback report</Heading>
                )}
                <Stepper steps={2} currentStep={step} />
            </Flex>
            <Flex>
                {step === 1 && (
                    // In step 1 we animate the appearance of each transcript item individually
                    <Transcript
                        transcriptSections={transcriptSections}
                        animate
                    />
                )}
                {step === 2 && (
                    // In step 2 we animate blocks of transcript items (a.k.a. sections)
                    // so we need a visually equivalent component to individual items, but with first level children
                    // being the sections
                    <TranscriptSections
                        transcriptSections={transcriptSections}
                        onSectionAnimated={sectionId => {
                            setTranscriptSections(sections => {
                                const newSections = sections.filter(
                                    ({ id }) => id !== sectionId,
                                );
                                if (newSections.length < 8) {
                                    return newSections.concat(
                                        getTranscriptSections(3),
                                    );
                                }
                                return newSections;
                            });
                        }}
                    />
                )}
            </Flex>
        </Center>
    );
};

export default LoadingAnimation;
