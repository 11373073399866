import React from 'react';
import InteractionFeedbackForm from '../components/InteractionFeedbackForm';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';

interface Props {
    actionId: number;
    show: boolean;
    onClose: () => void;
    onSubmit: (post: any) => Promise<any>;
}

const InteractionFeedbackModal: React.FC<Props> = ({
    actionId,
    show,
    onClose,
    onSubmit,
}) => {
    return (
        <Modal isOpen={show} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Interaction feedback</ModalHeader>
                <ModalBody>
                    <InteractionFeedbackForm
                        closeModal={onClose}
                        actionId={actionId}
                        onSubmit={onSubmit}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default InteractionFeedbackModal;
