import React, { Fragment } from 'react';
import {
    Center,
    Flex,
    Tooltip,
    IconButton,
    Text,
    VStack,
    Heading,
    HStack,
    ChakraProvider,
} from '@chakra-ui/react';
import InteractionModeSwitch from '../InteractionModeSwitch/InteractionModeSwitch';
import { Icon } from '../../atoms';
import NavbarToggle from '../../molecules/NavbarToggle/NavbarToggle';
import { themeWhiteShadows } from '../../theme';
import { isMobile } from 'react-device-detect';
import { useInteractionSetting } from '../../stores';
import { CaptionSetting, ModeSetting } from '../../stores/interaction-settings';

interface Props {
    title: string;
    onClickClose?: () => void;
}

const Navbar: React.FC<Props> = ({ title, onClickClose }) => {
    const { isCaptionsOn, toggleCaptions } = useInteractionSetting<
        CaptionSetting
    >('captions');

    const { isTextMode, setIsTextMode } = useInteractionSetting<ModeSetting>(
        'mode',
    );

    return (
        <ChakraProvider theme={themeWhiteShadows} cssVarsRoot='#navbar'>
            <Flex
                id='navbar'
                p='10px'
                position='fixed'
                w='100%'
                justifyContent='space-between'
                zIndex={20}
            >
                <Center>
                    <HStack>
                        <InteractionModeSwitch
                            isTextMode={isTextMode}
                            setIsTextMode={setIsTextMode}
                        />
                        {!isTextMode && isMobile && (
                            <NavbarToggle
                                name='Captions'
                                icon='captions'
                                isToggle={isCaptionsOn}
                                onClick={toggleCaptions}
                            />
                        )}
                    </HStack>
                </Center>
                <VStack>
                    {isTextMode && (
                        <Fragment>
                            <Heading color='white' fontSize='lg' mt='5px'>
                                {title}
                            </Heading>
                            <Text
                                color='dark.400'
                                fontSize='md'
                                mt='-5px!important'
                            >
                                By Recourse AI
                            </Text>
                        </Fragment>
                    )}
                </VStack>
                <Center>
                    <Tooltip label='End conversation' placement='left'>
                        <IconButton
                            aria-label='End conversation button'
                            rounded='full'
                            backgroundColor='black'
                            border='1px solid'
                            borderColor='dark.500'
                            _hover={{ backgroundColor: 'black' }}
                            onClick={onClickClose}
                        >
                            <Icon
                                iconChoice='x'
                                fontAwesomeProps={{ color: 'white' }}
                            />
                        </IconButton>
                    </Tooltip>
                </Center>
            </Flex>
        </ChakraProvider>
    );
};

export default Navbar;
