import { Formik, FormikValues } from 'formik';
import React, { Fragment, useState } from 'react';
import { Button, Form, Spinner, Alert } from 'react-bootstrap';
import { capitalizeFirstLetter } from '../../../../utils/stringUtils';
import * as Sentry from '@sentry/browser';

interface Props {
    actionId: number;
    onSubmit: (post: any) => Promise<any>;
    closeModal: () => void;
}

const InteractionFeedbackForm: React.FC<Props> = ({
    actionId,
    onSubmit,
    closeModal,
}) => {
    const [serverError, setServerError] = useState<boolean>();

    const validate = (values: {
        [name: string]: string;
    }): {
        [key: string]: string | null | undefined;
    } => {
        const errors: {
            feedbackMessage?: null | string;
            category?: null | string;
            agentActionId?: null | string;
        } = {};

        if (!values.feedbackMessage || values.feedbackMessage === '') {
            errors.feedbackMessage = 'Please write your feedback.';
        }

        if (!values.category || values.category === 'default') {
            errors.category = 'Please select a category.';
        }

        if (!values.agentActionId) {
            errors.agentActionId =
                'Something went wrong with this form, please try refreshing the page.';
        }

        return errors;
    };

    const submit = (
        values: FormikValues,
        {
            setSubmitting,
            resetForm,
        }: { setSubmitting: (val: boolean) => void; resetForm: () => void },
    ): void => {
        setSubmitting(true);

        onSubmit({
            category: values.category,
            message: values.feedbackMessage,
            agent_action_id: actionId,
        })
            .then(() => {
                setSubmitting(false);
                resetForm();
                closeModal();
            })
            .catch((error: any) => {
                setSubmitting(false);
                Sentry.captureException(error);
                setServerError(true);
            });
    };

    const categories = ['issue', 'suggestion', 'other'];
    const initialValues = {
        agentActionId: actionId.toString(),
        category: 'default',
        feedbackMessage: '',
    };
    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={submit}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
            }): JSX.Element => (
                <Form
                    noValidate
                    id='interaction_feedback'
                    onSubmit={handleSubmit}
                >
                    {serverError && (
                        <Alert variant='danger'>
                            Something went wrong submitting the form, please try
                            again.
                        </Alert>
                    )}
                    <Form.Group controlId='agentActionId'>
                        <Form.Control
                            name='agentActionId'
                            type='number'
                            disabled={true}
                            hidden={true}
                            isInvalid={!!errors.agentActionId}
                            onChange={handleChange}
                            value={values.agentActionId}
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.agentActionId}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='category'>
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                            name='category'
                            type='select'
                            as='select'
                            isInvalid={touched.category && !!errors.category}
                            onChange={handleChange}
                            value={values.category}
                            style={{ width: '80%' }}
                            className='mb-2'
                        >
                            <option key={-1} value={'default'}>
                                Please select a category
                            </option>
                            {categories.map((category, index) => {
                                return (
                                    <option key={index} value={category}>
                                        {capitalizeFirstLetter(category)}
                                    </option>
                                );
                            })}
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            {errors.category}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='feedbackMessage'>
                        <Form.Label>Feedback</Form.Label>
                        <Form.Control
                            name='feedbackMessage'
                            as='textarea'
                            isInvalid={
                                touched.category && !!errors.feedbackMessage
                            }
                            onChange={handleChange}
                            value={values.feedbackMessage}
                            style={{ flex: 1, minHeight: 140 }}
                            className={'mb-2 mt-2'}
                            type='textarea'
                            placeholder={'Write your feedback here'}
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.feedbackMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Row
                        style={{
                            marginTop: 15,
                            marginBottom: 10,
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            variant='dark'
                            type='submit'
                            id='send'
                            disabled={isSubmitting}
                        >
                            {isSubmitting && (
                                <Fragment>
                                    <Spinner
                                        as='span'
                                        animation='border'
                                        size='sm'
                                        role='status'
                                        aria-hidden='true'
                                        style={{ marginRight: 5 }}
                                    />
                                    <span className='sr-only'>Loading...</span>
                                    Submitting...
                                </Fragment>
                            )}
                            {!isSubmitting && <Fragment>Submit</Fragment>}
                        </Button>
                    </Form.Row>
                </Form>
            )}
        </Formik>
    );
};

export default InteractionFeedbackForm;
