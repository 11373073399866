export const updateTextareaRows = (
    textarea: HTMLTextAreaElement,
    minRows: number,
    maxRows: number,
): void => {
    textarea.rows = minRows;
    let { clientHeight, scrollHeight } = textarea;

    while (textarea.rows < maxRows && clientHeight < scrollHeight) {
        textarea.rows += 1;
        clientHeight = textarea.clientHeight;
        scrollHeight = textarea.scrollHeight;
    }
};

export const isInputElement = (elem: HTMLElement | undefined): boolean => {
    const inputs = ['input', 'select', 'textarea'];
    return !!elem && inputs.includes(elem.tagName.toLowerCase());
};
