import React, { RefObject } from 'react';
import { Textarea } from '@chakra-ui/react';
import { updateTextareaRows } from '../../utils';
import { scrollBarStyles } from '../../theme/consts';

interface Props {
    placeholder?: string;
    inputRef: RefObject<HTMLTextAreaElement>;
    handleKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    value: string;
    handleChange: (e: React.ChangeEvent<any>) => void;
}

const textAreaNoHover = {
    _hover: {
        borderColor: 'inherit',
    },
    _focus: {
        borderColor: 'inherit',
        boxShadow: 'none',
    },
    _active: {
        borderColor: 'inherit',
        boxShadow: 'none',
    },
};

const UserInputField: React.FC<Props> = ({
    placeholder = 'Send your message',
    inputRef,
    handleKeyDown,
    value,
    handleChange,
}) => {
    return (
        <Textarea
            name='message'
            ref={inputRef}
            placeholder={placeholder}
            rows={1}
            resize='none'
            color='white'
            border='none'
            h='fit-content'
            mr='10px'
            p='0px 5px 0px 0px'
            sx={{ ...scrollBarStyles, ...textAreaNoHover }}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                if (inputRef.current) {
                    updateTextareaRows(inputRef.current, 1, 4);
                }
                handleChange(e);
            }}
            onKeyDown={handleKeyDown}
        />
    );
};

export default UserInputField;
