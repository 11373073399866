import {
    Button,
    Heading,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import { ModalWithNoInitialFocus } from '../../../components/overlays';
import React from 'react';
import { ModalProps } from '../../../components/modals/types';

interface Props extends ModalProps {
    onStart?: () => void;
    startButtonText?: string;
}

const InstructionModal: React.FC<Props> = ({
    show,
    onClose,
    onStart,
    children,
    startButtonText,
}) => {
    return (
        <ModalWithNoInitialFocus
            size='5xl'
            scrollBehavior='inside'
            isOpen={show}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Heading>Instructions</Heading>
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody
                    tabIndex={0}
                    role='group'
                    aria-label='instructions-content'
                >
                    {children}
                </ModalBody>
                {onStart !== undefined && (
                    <ModalFooter justifyContent='center'>
                        <Button
                            size='lg'
                            px={8}
                            colorScheme='brand.black'
                            onClick={() => {
                                onClose();
                                onStart();
                            }}
                        >
                            {startButtonText}
                        </Button>
                    </ModalFooter>
                )}
            </ModalContent>
        </ModalWithNoInitialFocus>
    );
};

export default InstructionModal;
