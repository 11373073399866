import React, { Fragment } from 'react';
import { curry } from 'lodash';
import {
    Box,
    Button,
    Heading,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import Transcript from './Transcript';
import {
    InteractionFeedback,
    InteractionStageFeedback,
    MarkingSchemeType,
} from './types';
import ConsultationFeedbackModalContent from './Consultation';
import DialogueChoicesFeedback from './DailogueChoices';
import TutorFeedback from './Tutor';
import { ModalProps } from '../types';
import { ModalWithNoInitialFocus } from '../../overlays';
import LLMFeedback from './LLMGenerated';
import LoadingAnimation from './LoadingAnimation';
import LLMChecklistFeedback from './LLMChecklistFeedback';

type InteractionStageFeedbackComponent<MarkingFeedbackType> = React.FC<
    InteractionStageFeedback<MarkingFeedbackType>
>;
const InteractionStageMarkingTypeComponentModalContentMapping: Record<
    MarkingSchemeType,
    InteractionStageFeedbackComponent<any>
> = {
    conversation: ConsultationFeedbackModalContent,
    dialogue_choices: DialogueChoicesFeedback,
    elicit: TutorFeedback,
    llm: LLMFeedback,
    llm_checklist: LLMChecklistFeedback,
};

export const feedbackToComponentArray = <MarkingFeedbackType,>(
    feedback: AttemptFeedback<InteractionFeedback<MarkingFeedbackType>> | null,
    Component?: InteractionStageFeedbackComponent<MarkingFeedbackType>,
): JSX.Element[] => {
    const stages =
        (feedback?.feedback as InteractionFeedback<MarkingFeedbackType>)
            ?.stages || [];
    return stages.map(stage => {
        let _Component = Component;
        if (_Component === undefined) {
            _Component =
                InteractionStageMarkingTypeComponentModalContentMapping[
                    stage.stage.interaction_stage.marking_scheme_type
                ] ?? Fragment;
        }

        if (stages.length > 1) {
            return (
                <Box key={stage.stage.id}>
                    <Heading size='lg' my={2}>
                        {stage.stage.interaction_stage.name}
                    </Heading>
                    <_Component {...stage} />
                </Box>
            );
        } else {
            return <_Component key={stage.stage.id} {...stage} />;
        }
    });
};

export interface InteractionFeedbackModalProps extends ModalProps {
    feedback: AttemptFeedback<InteractionFeedback<any>> | null;
}

export const InteractionFeedbackModalContent: React.FC<{
    feedback: InteractionFeedbackModalProps['feedback'];
    height?: string;
}> = ({ feedback, height }) => {
    const stageComponents = curry(feedbackToComponentArray)(feedback);
    const skipFeedback =
        !feedback?.header_html &&
        !feedback?.footer_html &&
        feedback?.feedback?.stages.every(
            stage => stage.marking_feedback === null,
        );

    return (
        <Tabs colorScheme='brand.black'>
            <TabList>
                {!skipFeedback && <Tab>Feedback</Tab>}
                <Tab>Transcript</Tab>
            </TabList>

            <TabPanels maxHeight={height} overflowY='auto'>
                {!skipFeedback && (
                    <TabPanel p={2}>
                        {feedback?.header_html && (
                            <Box
                                pt={2}
                                borderColor='brand.black.400'
                                borderBottomWidth={2}
                                dangerouslySetInnerHTML={{
                                    __html: feedback?.header_html,
                                }}
                            />
                        )}
                        {/*Explicitly pass undefined because we can't do  default params on a curried function in js*/}
                        {stageComponents(undefined)}
                        {feedback?.footer_html && (
                            <Box
                                pt={2}
                                borderColor='brand.black.400'
                                borderTopWidth={2}
                                dangerouslySetInnerHTML={{
                                    __html: feedback?.footer_html,
                                }}
                            />
                        )}
                    </TabPanel>
                )}
                <TabPanel maxHeight='inherit' overflowY='inherit'>
                    {stageComponents(Transcript)}
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

const InteractionFeedbackModal: React.FC<InteractionFeedbackModalProps> = ({
    show,
    onClose,
    onExited,
    feedback,
}) => {
    const hasLoaded = feedback !== null;
    return (
        <ModalWithNoInitialFocus
            isOpen={show}
            onClose={onClose}
            onCloseComplete={onExited}
            scrollBehavior='inside'
            size='5xl'
        >
            <ModalOverlay />
            <ModalContent minHeight='min(70vh, 100%)'>
                {!hasLoaded && (
                    <ModalBody
                        minHeight='min(70vh, 100%)'
                        py={2}
                        tabIndex={0}
                        role='group'
                        aria-label='feedback-content'
                        overflow='hidden'
                    >
                        <LoadingAnimation />
                    </ModalBody>
                )}
                {hasLoaded && (
                    <Fragment>
                        <ModalBody
                            minHeight='min(70vh, 100%)'
                            tabIndex={0}
                            role='group'
                            aria-label='feedback-content'
                        >
                            <InteractionFeedbackModalContent
                                feedback={feedback}
                            />
                        </ModalBody>
                        <ModalFooter justifyContent='center'>
                            <Button colorScheme='brand.black' onClick={onClose}>
                                Continue
                            </Button>
                        </ModalFooter>
                    </Fragment>
                )}
            </ModalContent>
        </ModalWithNoInitialFocus>
    );
};

export default InteractionFeedbackModal;
