import React, { useState, Fragment, CSSProperties, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import InteractionFeedbackModal from '../modals/InteractionFeedbackModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import WithTooltip from '../../../generic/WithTooltip';
import APIService from '../../../../services/APIService';
import config from '../../../../consts/config';

interface Props {
    actionId: number;
    style?: CSSProperties;
    className?: string;
    showButton: boolean;
    onOpen?: () => void;
}

const SendFeedback: React.FC<Props> = ({
    actionId,
    style,
    className,
    showButton,
    onOpen,
}) => {
    const submitFeedback = useCallback((post: any): Promise<any> => {
        const api = new APIService(config.REACT_APP_INTERACTIONS_URL!);
        return api.post('user_feedback/agent_action', {
            ...post,
        });
    }, []);

    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <Fragment>
            {showButton && (
                <WithTooltip hoverMessage={'Submit feedback for response'}>
                    {({ ref, ...triggerHandler }) => (
                        <Button
                            variant='link'
                            {...triggerHandler}
                            className={className}
                            style={style}
                            onClick={() => {
                                if (onOpen) {
                                    onOpen();
                                }
                                setShowModal(true);
                            }}
                        >
                            <span ref={ref}>
                                <FontAwesomeIcon
                                    icon={faExclamationCircle}
                                    color={'var(--secondary)'}
                                />
                            </span>
                        </Button>
                    )}
                </WithTooltip>
            )}
            <InteractionFeedbackModal
                actionId={actionId}
                show={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={submitFeedback}
            />
        </Fragment>
    );
};

export default SendFeedback;
