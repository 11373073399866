import React from 'react';
import { Button } from '@chakra-ui/react';

interface Props {
    text: string;
    onClick: (hint: string) => void;
}

const Hint: React.FC<Props> = ({ text, onClick }) => {
    return (
        <Button
            minW='150px'
            maxW='150px'
            h='80px'
            px='10px'
            whiteSpace='normal'
            backgroundColor='dark.900'
            border='1px solid'
            borderColor='dark.500'
            color='white'
            fontSize='sm'
            fontWeight='normal'
            sx={{
                _focus: { boxShadow: 'none' },
                _active: { backgroundColor: 'none' },
            }}
            _hover={{ opacity: '75%' }}
            onClick={() => onClick(text)}
        >
            {text}
        </Button>
    );
};

export default Hint;
