import React, { useCallback, useRef } from 'react';
import IntervalStream from '../utils/IntervalStream';
import {
    AgentTextMessage,
    AvatarText,
    Citation,
    MediaAttachment,
    TextMessage,
} from '../types';

const useTypewriterEffect = (
    charPrintInterval: number,
    setMessages: React.Dispatch<React.SetStateAction<TextMessage[]>>,
) => {
    const agentMessageBufferMap = useRef(
        new Map<number, IntervalStream<string | Citation>[]>(),
    );

    const displayTextual = useCallback(
        (
            textual: AvatarText,
            actionId: number,
            continuous = false,
            hidden: boolean,
            attachments: MediaAttachment[] = [],
            onEnd?: () => void,
        ): void => {
            const addActionMessage = (
                messages: TextMessage[],
                text: AvatarText,
                actionId: number,
                skipIfAlreadyExists = false,
            ): TextMessage[] => {
                // Callback setter for messages
                const newMessages = [...messages];
                const relevantMessageIndex = newMessages.findIndex(
                    m => m.type === 'agent' && m.actionId === actionId,
                );
                if (skipIfAlreadyExists && relevantMessageIndex !== -1)
                    return newMessages;

                if (relevantMessageIndex !== -1) {
                    const newMessage = newMessages[
                        relevantMessageIndex
                    ] as AgentTextMessage;

                    newMessage.text.push(...text);

                    if (attachments) {
                        if (newMessage.attachments === undefined) {
                            newMessage.attachments = [];
                        }

                        const newAttachments = attachments.filter(
                            incomingAttachment =>
                                !newMessage.attachments?.some(
                                    prevAttachement =>
                                        prevAttachement.name ===
                                        incomingAttachment.name,
                                ),
                        );
                        newMessage.attachments!.push(...newAttachments);
                    }
                } else {
                    newMessages.push({
                        type: 'agent',
                        actionId,
                        text: text,
                        attachments,
                        className: hidden ? 'font-italic' : undefined,
                    });
                }
                return newMessages;
            };

            if (continuous && !hidden) {
                const intervalStream = new IntervalStream<string | Citation>(
                    charPrintInterval,
                );
                intervalStream.setCallback(nextChar => {
                    setMessages(messages =>
                        addActionMessage(messages, [nextChar], actionId),
                    );
                });
                intervalStream.setOnEnd(() => {
                    const streamsForAction = agentMessageBufferMap.current.get(
                        actionId,
                    );
                    if (streamsForAction) {
                        streamsForAction.shift();
                        if (streamsForAction.length) {
                            const nextStream = streamsForAction[0];
                            nextStream.start();
                        }
                    }
                    onEnd && onEnd();
                });

                if (!agentMessageBufferMap.current.has(actionId)) {
                    agentMessageBufferMap.current.set(actionId, []);
                }

                const streams = agentMessageBufferMap.current.get(actionId)!;
                streams.push(intervalStream);
                intervalStream.put(...textual, ' ');
                intervalStream.setCompleted(true);

                if (streams.length === 1) {
                    intervalStream.start();
                }
            } else {
                // We don't send the process action for single text
                // as there is no delay for making the update
                // act(InteractionActions.process);
                setMessages(messages =>
                    addActionMessage(messages, textual, actionId, true),
                );
                onEnd && onEnd();
            }
        },
        [charPrintInterval, setMessages],
    );

    const stop = useCallback((actionId: number) => {
        const streams = agentMessageBufferMap.current.get(actionId);
        agentMessageBufferMap.current.delete(actionId);
        streams?.forEach(stream => stream.stop());
    }, []);

    return { displayTextual, stop };
};

export default useTypewriterEffect;
