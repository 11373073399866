import React, { Fragment } from 'react';
import { InteractionStageFeedback } from '../types';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Flex,
    Heading,
    HStack,
    ListItem,
    Text,
    UnorderedList,
} from '@chakra-ui/react';
import { LLMChecklistStage, Item } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const PERFECT_SCORE_MESSAGE =
    "Congratulations! You've passed the FIT assessment!";
const PERFECT_ITEM_MESSAGE =
    "Congratulations! You've met all the passing criteria!";

const failIconProps = { icon: faTimes, color: 'red.500' };
const passIconProps = { icon: faCheck, color: 'green.500' };

const FeedbackItem: React.FC<Item> = ({
    title,
    score,
    description,
    criteria,
}) => (
    <AccordionItem>
        <AccordionButton
            bg='brand.black.100'
            _hover={{ bg: 'brand.black.200' }}
        >
            <Flex flex='1' justifyContent='space-between' fontWeight='bold'>
                <Box>{title}</Box>
                <Box>{score}%</Box>
            </Flex>
            <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py={2}>
            {score === 100 ? (
                <HStack color={passIconProps.color} alignItems='center'>
                    <FontAwesomeIcon {...passIconProps} />
                    <Text py={0}> {PERFECT_ITEM_MESSAGE} </Text>
                </HStack>
            ) : (
                <Fragment>
                    <HStack color={failIconProps.color} alignItems='center'>
                        <FontAwesomeIcon {...failIconProps} />
                        <Text py={0} fontWeight='bold'>
                            {description}
                        </Text>
                    </HStack>
                    <Flex>
                        <UnorderedList>
                            {criteria
                                .filter(c => c.score === 'fail')
                                .map(c => (
                                    <ListItem
                                        key={c.criterion}
                                        textColor={failIconProps.color}
                                    >
                                        {c.reasoning}
                                    </ListItem>
                                ))}
                        </UnorderedList>
                    </Flex>
                </Fragment>
            )}
        </AccordionPanel>
    </AccordionItem>
);

const LLMChecklistFeedback: React.FC<InteractionStageFeedback<
    LLMChecklistStage
>> = ({ marking_feedback }) => {
    if (!marking_feedback) return null;

    const { score } = marking_feedback.summary;
    const sections = marking_feedback.sections;

    const failedSectionsIdx = sections
        .map((section, idx) => (section.score < 100 ? idx : -1))
        .filter(idx => idx >= 0);

    return (
        <Box>
            <Heading size='md' p={4} bg='black' color='white' roundedTop='15px'>
                <Flex justifyContent='space-between'>
                    <Box>FIT Assessment</Box>
                    <Box>{score}%</Box>
                </Flex>
            </Heading>
            <Box>
                {score === 100 ? (
                    <HStack color={passIconProps.color} alignItems='center'>
                        <FontAwesomeIcon {...passIconProps} />
                        <Text py={2}>{PERFECT_SCORE_MESSAGE}</Text>
                    </HStack>
                ) : (
                    <Accordion defaultIndex={failedSectionsIdx} allowMultiple>
                        {sections.map(item => (
                            <FeedbackItem key={item.title} {...item} />
                        ))}
                    </Accordion>
                )}
            </Box>
        </Box>
    );
};

export default LLMChecklistFeedback;
