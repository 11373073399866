import React, { Fragment } from 'react';
import { Text } from '@chakra-ui/react';

export const RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE = {
    title: 'The session has disconnected',
    content: (
        <Fragment>
            <p>
                You have taken longer than the recommended time to complete this
                activity.
            </p>
            <p>
                Don’t worry, all your progress is saved. Simply rejoin and
                continue with your conversation, or you may finish and see your
                feedback.
            </p>
        </Fragment>
    ),
};

export const RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE = {
    title: 'Try optimizing your setup',
    content: (
        <Fragment>
            <p className='font-weight-bold'>
                Please ensure you close other applications and tabs/windows and
                plug in charger.
            </p>
            <p>
                You can continue in text-mode and always enable the avatar again
                later by clicking the avatar icon.
            </p>
            <p>
                If you continue to have issues, please reach out to our support
                team at{' '}
                <a href='mailto:support@recourseai.com'>
                    support@recourseai.com
                </a>{' '}
                who will be more than happy to help.
            </p>
        </Fragment>
    ),
    modalProps: {
        size: 'lg',
    },
};

export const RAPPORT_SESSION_COULD_NOT_INITIALISE = {
    title: "It seems we're having trouble loading your avatar at the moment.",
    content: (
        <Fragment>
            <dl>
                <dt>
                    <strong>Try Again Later</strong>
                </dt>
                <dd>
                    <Text paddingLeft='3'>
                        You can wait for a while and try loading your avatar
                        again later or continue the interaction using text chat
                        (without the avatar). The loading process may succeed on
                        a subsequent attempt.
                    </Text>
                </dd>
            </dl>
            <p>
                If you continue to have issues, please reach out to our support
                team at{' '}
                <a href='mailto:support@recourseai.com'>
                    support@recourseai.com
                </a>{' '}
                who will be more than happy to help.
            </p>
        </Fragment>
    ),
    modalProps: {
        size: '2xl',
    },
};
