import React, { Fragment } from 'react';
import { Text } from '@chakra-ui/react';
import CitationButton from '../CitationButton/CitationButton';
import { getChatStyles } from '../../theme/consts';
import { AvatarText, Citation } from '../../../../core/src/types';

interface Props {
    text: AvatarText;
    onCitationClick: (citation: Citation) => void;
    isTextMode: boolean;
}

const AvatarMessage: React.FC<Props> = ({
    text,
    onCitationClick,
    isTextMode,
}) => {
    const styles = getChatStyles(isTextMode);

    return (
        <Text
            borderRadius='10px'
            mb='5px'
            color='white'
            wordBreak='break-word'
            {...styles.avatarMessage}
        >
            {text.map((item, i) => {
                if (typeof item === 'string') {
                    return <Fragment key={i}>{item}</Fragment>;
                } else {
                    return (
                        <CitationButton
                            key={i}
                            citationId={item.id}
                            onClick={() => {
                                onCitationClick(item);
                            }}
                        />
                    );
                }
            })}
        </Text>
    );
};

export default AvatarMessage;
