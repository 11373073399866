import React, { Fragment } from 'react';
import {
    Badge,
    Button,
    Card,
    Col,
    ListGroup,
    Row,
    Tab,
    Tabs,
} from 'react-bootstrap';
import { ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faChevronDown,
    faMinusCircle,
    faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { InvestigationFeedback } from './types';
import WithTooltip from '../../generic/WithTooltip';
import TimeShorthand from '../../generic/TimeShorthand';
import RenderWithLoading from '../../generic/RenderWithLoading';
import { formatTotalTime } from '../../activities/investigation/components/MyOrders';
import { ModalProps } from '../types';
import { ModalWithNoInitialFocus } from '../../overlays';

interface Props extends ModalProps {
    feedback: AttemptFeedback<InvestigationFeedback> | null;
}

interface TaggedInvestigation extends Investigation {
    tag?: 'CORRECT' | 'EXTRA' | 'MISSED';
}

const TagTooltip = {
    CORRECT: 'Correct',
    EXTRA: 'Unnecessary',
    MISSED: 'Missed',
};

interface TaggedInvestigationOrder {
    investigations: TaggedInvestigation[];
}

interface TaggedFeedback {
    optimal_orders: {
        optimal_orders_list: TaggedInvestigation[];
    } & InvestigationFeedback['optimal_orders'];
    user_orders: {
        orders: TaggedInvestigationOrder[];
    } & InvestigationFeedback['user_orders'];
}

const tagFeedback = (feedback: InvestigationFeedback): TaggedFeedback => {
    const myOrders: TaggedInvestigationOrder[] = feedback.user_orders.orders;
    const optInvestigations: TaggedInvestigation[] =
        feedback.optimal_orders.optimal_orders_list;

    for (const opInv of optInvestigations) {
        opInv.tag = 'MISSED';
        for (const order of myOrders) {
            for (const investigation of order.investigations) {
                if (opInv.id === investigation.id) {
                    if (opInv.tag === 'MISSED') {
                        opInv.tag = 'CORRECT';
                        investigation.tag = 'CORRECT';
                    } else {
                        investigation.tag = 'EXTRA';
                    }
                } else if (investigation.tag !== 'CORRECT') {
                    investigation.tag = 'EXTRA';
                }
            }
        }
    }

    const taggedFeedback: TaggedFeedback = feedback;
    taggedFeedback.user_orders.orders = myOrders;
    taggedFeedback.optimal_orders.optimal_orders_list = optInvestigations;

    return taggedFeedback;
};

const OrderItem: React.FC<{
    orderItemVal: TaggedInvestigation;
    disableColorCoding?: boolean;
}> = ({ orderItemVal, disableColorCoding = false }): JSX.Element => {
    return (
        <ListGroup.Item className='p-2'>
            <Row noGutters className='align-items-center'>
                <Col
                    sm={6}
                    className='text-left'
                    style={{
                        color: !disableColorCoding
                            ? orderItemVal.tag === 'CORRECT'
                                ? 'green'
                                : 'red'
                            : undefined,
                    }}
                >
                    {disableColorCoding && <span className='px-2 ml-2' />}
                    {!!orderItemVal.tag && !disableColorCoding && (
                        <WithTooltip
                            hoverMessage={TagTooltip[orderItemVal.tag]}
                        >
                            <span className='px-2'>
                                <FontAwesomeIcon
                                    color={
                                        orderItemVal.tag === 'CORRECT'
                                            ? 'green'
                                            : 'red'
                                    }
                                    icon={
                                        orderItemVal.tag !== 'CORRECT'
                                            ? orderItemVal.tag === 'MISSED'
                                                ? faMinusCircle
                                                : faPlusCircle
                                            : faCheckCircle
                                    }
                                />
                            </span>
                        </WithTooltip>
                    )}
                    {orderItemVal.name}
                </Col>
                <Col sm={3} className='text-center'>
                    {orderItemVal.cost}
                </Col>
                <Col sm={3} className='text-center'>
                    {orderItemVal.time_estimated && (
                        <TimeShorthand>
                            {orderItemVal.time_estimated}
                        </TimeShorthand>
                    )}
                </Col>
            </Row>
        </ListGroup.Item>
    );
};

const OrderSet: React.FC<{
    orderSetVal: TaggedInvestigationOrder;
    orderSetIndex: number;
}> = ({ orderSetVal, orderSetIndex }): JSX.Element => {
    return (
        <Card className='my-2 border-0'>
            <Card.Title
                className={`${orderSetIndex &&
                    'mt-2'} text-primary ml-3 h6 mb-1`}
            >
                {`Order ${orderSetIndex + 1}`}
            </Card.Title>
            <ListGroup>
                {orderSetVal.investigations.map((orderVal, orderIndex) => (
                    <OrderItem orderItemVal={orderVal} key={orderIndex} />
                ))}
            </ListGroup>
        </Card>
    );
};

export const InvestigationModalContent: React.FC<Pick<Props, 'feedback'> & {
    height?: string;
}> = ({ feedback, height }) => {
    const taggedFeedback =
        feedback &&
        feedback.feedback &&
        tagFeedback(feedback.feedback as InvestigationFeedback);
    const missedItems = taggedFeedback?.optimal_orders.optimal_orders_list.filter(
        tf => tf.tag === 'MISSED',
    );

    return (
        <Fragment>
            <style>{`
                .nav-tabs {
                    padding-bottom: 5px;
                    margin-bottom: 10px;
                }
                .nav-tabs .nav-item {
                    color: rgba(0, 0, 0, 0.5);
                    border-color: transparent!important;
                }
                .nav-tabs .nav-item.active {
                    color: var(--primary);
                    font-weight: bold!important;
                }
                .tab-pane {
                    max-height: 80vh;
                    overflow-y: auto;
                    overflow-x: hidden;
                }
                .overflow-container {
                    max-height: 65vh;
                    overflow-y: auto;
                }
            `}</style>
            <Tabs id='feedback-modal' defaultActiveKey='feedback'>
                <Tab
                    eventKey='feedback'
                    title='Feedback'
                    style={{
                        height: height,
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div id='summary' className='mb-4'>
                        <Row
                            noGutters
                            className='p-3 mb-0 h5 bg-primary text-center text-light'
                            style={{
                                borderTopLeftRadius: 20,
                                borderTopRightRadius: 20,
                            }}
                        >
                            <Col className='text-left'>Summary</Col>
                        </Row>
                        <Row
                            noGutters
                            className='py-2 px-3 font-weight-bold align-items-center marking-section-divider'
                        >
                            <Col sm={6} />
                            <Col sm={3} className='text-center'>
                                My orders
                            </Col>
                            <Col sm={3} className='text-center'>
                                Optimal orders
                            </Col>
                        </Row>
                        <Row noGutters className='py-2 px-3 align-items-center'>
                            <Col sm={6} className='text-left font-weight-bold'>
                                Order count
                            </Col>
                            <Col sm={3} className='text-center'>
                                {taggedFeedback?.user_orders.orders
                                    .map(order => order.investigations.length)
                                    .reduce((prev, curr) => prev + curr)}
                            </Col>
                            <Col sm={3} className='text-center'>
                                {
                                    taggedFeedback?.optimal_orders
                                        .optimal_orders_list.length
                                }
                            </Col>
                        </Row>
                        {taggedFeedback?.user_orders.total_cost &&
                            taggedFeedback?.optimal_orders.total_cost && (
                                <Row
                                    noGutters
                                    className='py-2 px-3 align-items-center'
                                >
                                    <Col
                                        sm={6}
                                        className='text-left font-weight-bold'
                                    >
                                        Spending
                                    </Col>
                                    <Col sm={3} className='text-center'>
                                        {taggedFeedback.user_orders.total_cost}
                                    </Col>
                                    <Col sm={3} className='text-center'>
                                        {
                                            taggedFeedback.optimal_orders
                                                .total_cost
                                        }
                                    </Col>
                                </Row>
                            )}
                        {taggedFeedback?.user_orders.total_time_estimated &&
                            taggedFeedback?.optimal_orders
                                .total_time_estimated && (
                                <Row
                                    noGutters
                                    className='py-2 px-3 align-items-center'
                                >
                                    <Col
                                        sm={6}
                                        className='text-left font-weight-bold'
                                    >
                                        Time estimate
                                    </Col>
                                    <Col sm={3} className='text-center'>
                                        {taggedFeedback && (
                                            <TimeShorthand>
                                                {formatTotalTime(
                                                    taggedFeedback.user_orders
                                                        .total_time_estimated,
                                                )}
                                            </TimeShorthand>
                                        )}
                                    </Col>
                                    <Col sm={3} className='text-center'>
                                        {taggedFeedback && (
                                            <TimeShorthand>
                                                {formatTotalTime(
                                                    taggedFeedback
                                                        .optimal_orders
                                                        .total_time_estimated,
                                                )}
                                            </TimeShorthand>
                                        )}
                                    </Col>
                                </Row>
                            )}
                    </div>
                    <Row
                        noGutters
                        className='p-3 mb-0 h5 bg-primary text-center text-light'
                        style={{
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20,
                        }}
                    >
                        <Col className='text-left'>Performance</Col>
                    </Row>
                    <Row
                        noGutters
                        className='py-2 px-3 font-weight-bold align-items-center marking-section-divider'
                        onClick={() => {
                            document
                                .getElementById(
                                    'missed-orders-expander-chevron',
                                )
                                ?.classList.toggle('flip');
                            document
                                .getElementById('missed-orders-feedback-list')
                                ?.classList.toggle('show');
                        }}
                        role='button'
                    >
                        <Col sm={7} className='d-flex align-items-center'>
                            Missed
                            {missedItems && (
                                <Badge
                                    variant={
                                        missedItems.length
                                            ? 'danger'
                                            : 'success'
                                    }
                                    className='mx-1'
                                >
                                    {missedItems.length}
                                </Badge>
                            )}
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <i
                                className='expander-chevron'
                                id='missed-orders-expander-chevron'
                            >
                                <FontAwesomeIcon icon={faChevronDown} />
                            </i>
                        </Col>
                    </Row>
                    <div
                        id='missed-orders-feedback-list'
                        className={`collapse ${!!missedItems?.length &&
                            'mb-4'}`}
                    >
                        {missedItems?.map((orderVal, orderIndex) => (
                            <OrderItem
                                orderItemVal={orderVal}
                                key={orderIndex}
                                disableColorCoding
                            />
                        ))}
                    </div>

                    <Row
                        noGutters
                        className='py-2 px-3 font-weight-bold align-items-center marking-section-divider'
                        onClick={() => {
                            document
                                .getElementById('user-orders-expander-chevron')
                                ?.classList.toggle('flip');
                            document
                                .getElementById('user-orders-feedback-list')
                                ?.classList.toggle('show');
                        }}
                        role='button'
                    >
                        <Col sm={7}>My orders</Col>
                        <Col className='d-flex justify-content-end'>
                            <i
                                className='expander-chevron flip'
                                id='user-orders-expander-chevron'
                            >
                                <FontAwesomeIcon icon={faChevronDown} />
                            </i>
                        </Col>
                    </Row>
                    <div
                        id='user-orders-feedback-list'
                        className='collapse show'
                    >
                        {taggedFeedback?.user_orders.orders.map(
                            (orderSetVal, orderSetIndex) => (
                                <OrderSet
                                    orderSetVal={orderSetVal}
                                    orderSetIndex={orderSetIndex}
                                    key={orderSetIndex}
                                />
                            ),
                        )}
                    </div>
                </Tab>
            </Tabs>
        </Fragment>
    );
};

const InvestigationFeedbackModal: React.FC<Props> = ({
    show,
    onClose,
    onExited,
    feedback,
}) => {
    return (
        <ModalWithNoInitialFocus
            isOpen={show}
            onClose={onClose}
            onCloseComplete={onExited}
            size='5xl'
            closeOnOverlayClick={!!feedback}
            scrollBehavior='inside'
        >
            <ModalOverlay />
            <ModalContent>
                <ModalBody
                    minHeight='120px'
                    tabIndex={0}
                    role='group'
                    aria-label='feedback-content'
                >
                    <RenderWithLoading hasLoaded={feedback !== null}>
                        {/* 6.5rem from y-margins and bottom btn */}
                        <InvestigationModalContent feedback={feedback} />
                        <Row noGutters className='mt-auto'>
                            <Col className='mt-4 mb-2 text-center'>
                                <Button
                                    variant='primary'
                                    onClick={onClose}
                                    style={{ margin: '0 auto' }}
                                >
                                    Continue
                                </Button>
                            </Col>
                        </Row>
                    </RenderWithLoading>
                </ModalBody>
            </ModalContent>
        </ModalWithNoInitialFocus>
    );
};

export default InvestigationFeedbackModal;
