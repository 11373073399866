import React from 'react';
import {
    Text,
    Center,
    VStack,
    Avatar,
    Heading,
    BoxProps,
} from '@chakra-ui/react';

interface Props extends BoxProps {
    imageSrc: string;
    title: string;
}

const AvatarInfo: React.FC<Props> = ({ imageSrc, title, ...boxProps }) => {
    return (
        <Center h='100%' {...boxProps}>
            <VStack justifyContent='space-between'>
                <Avatar
                    src={imageSrc}
                    boxSize={{ base: '100px', md: '150px' }}
                />
                <Heading fontSize={{ base: '2xl', md: '3xl', lg: '4xl' }}>
                    {title}
                </Heading>
                <Text fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }}>
                    By Recourse AI
                </Text>
            </VStack>
        </Center>
    );
};

export default AvatarInfo;
