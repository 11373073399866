import React, { useRef } from 'react';
import { Center, InputGroup, IconButton, Tooltip } from '@chakra-ui/react';
import UserInputField from '../../atoms/UserInputField/UserInputField';
import { Icon } from '../../atoms';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useInteractionSetting } from '../../stores';
import { MicSetting } from '../../stores/interaction-settings';
import {
    InteractionContextAPI,
    InteractionContextStatus,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';

const validationSchema = Yup.object().shape({
    message: Yup.string()
        .required('Message cannot be empty')
        .matches(/^(?!\s*$).+/, 'Message cannot be empty'),
});

interface Props {
    onSubmit: (message: string) => void;
}

const MessageInputButton = ({ message }: { message: string }) => {
    const { isMicOn } = useInteractionSetting<MicSetting>('mic');
    const { awaitingResponse, isAgentBusy } = useInteractionContext(
        InteractionContextStatus,
    );
    const { interrupt } = useInteractionContext(InteractionContextAPI);

    if (isAgentBusy) {
        return (
            <Tooltip placement='bottom' label='Interrupt'>
                <IconButton
                    aria-label='Interrupt'
                    rounded='full'
                    onClick={interrupt}
                >
                    <Icon iconChoice='stop' />
                </IconButton>
            </Tooltip>
        );
    } else if (isMicOn) {
        return (
            <Tooltip placement='bottom' label='Speak now' isOpen>
                <IconButton aria-label='Speak now' rounded='full'>
                    <Icon
                        iconChoice='micOn'
                        fontAwesomeProps={{
                            color: 'green',
                        }}
                    />
                </IconButton>
            </Tooltip>
        );
    } else {
        return (
            <IconButton
                aria-label='Send message'
                rounded='full'
                type='submit'
                disabled={message === '' || awaitingResponse}
            >
                <Icon iconChoice='arrowUp' />
            </IconButton>
        );
    }
};

const MessageInput: React.FC<Props> = ({ onSubmit }) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const initialValues = {
        message: '',
    };

    const formSubmit = (
        values: FormikValues,
        { resetForm }: FormikHelpers<any>,
    ) => {
        onSubmit(values.message);
        resetForm();
        inputRef.current!.rows = 1;
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={formSubmit}
            validationSchema={validationSchema}
        >
            {({ values, errors, handleChange, handleSubmit }): JSX.Element => (
                <Form
                    style={{
                        width: '100%',
                    }}
                >
                    <InputGroup
                        backgroundColor='dark.700'
                        border='1px solid'
                        borderColor='dark.500'
                        borderRadius='10px'
                        p='10px'
                    >
                        <Center w='100%'>
                            <UserInputField
                                inputRef={inputRef}
                                value={values.message}
                                handleChange={handleChange}
                                handleKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSubmit();
                                    }
                                }}
                            />
                        </Center>
                        <Center>
                            <MessageInputButton message={values.message} />
                        </Center>
                    </InputGroup>
                </Form>
            )}
        </Formik>
    );
};

export default MessageInput;
