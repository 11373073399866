import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { isMobile, isDesktop } from 'react-device-detect';
import {
    Box,
    BoxProps,
    Button,
    ButtonProps,
    Center,
    HStack,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import { RapportAvatarConfig } from '../../../../core/src/types';
import useRapport from './useRapport';
import {
    InteractionContextAPI,
    InteractionContextStatus,
    useInteractionContext,
} from '../../utils/interaction/InteractionContext';
import {
    RAPPORT_SESSION_COULD_NOT_INITIALISE,
    RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
    RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
} from '../../../../../apps/mooc-frontend/src/components/activities/consultation/components/RapportModalTemplates';
import { ModalWithNoInitialFocus } from '../../atoms/ModalWithNoInitialFocus/ModalWithNoInitialFocus';
import {
    useInteractionSetting,
    useInteractionSettingsStore,
} from '../../stores';
import {
    AudioSetting,
    MicSetting,
    ModeSetting,
} from '../../stores/interaction-settings';
import { UserMessageSource } from '../../../../../apps/mooc-frontend/src/components/activities/consultation/components/types';
import useRefOfState from '../../../../core/src/hooks/useRefOfState';
import { getFps } from '../../../../../apps/mooc-frontend/src/components/activities/consultation/utils/frameRate';
// import { ThemingProps } from '@chakra-ui/system';

export const FPSDisplay = (props: BoxProps) => {
    const [fps, setFps] = useState(0);

    useEffect(() => {
        getFps(setFps);
    }, []);

    return (
        <Box backgroundColor='black' {...props}>
            <Text color='green.400' paddingX={1} paddingY={0} m={0}>
                {fps}
            </Text>
        </Box>
    );
};

export interface Props {
    backgroundSrc?: string;
    avatar_config: RapportAvatarConfig;
}

const DEFAULT_BACKGROUND_COLOR = '#EEEEEE';
const VIDEO_CLIP_PATH =
    'polygon(50% 0px, 80% 10%, 100% 35%, 100% 100%, 0% 100%, 0px 35%, 20% 10%)';

interface Modal {
    title: string;
    content: JSX.Element;
    actions: ({ label: string } & ButtonProps)[];
    modalProps?: any; // ThemingProps<'Modal'>;
}

export default function AvatarWrapper({ backgroundSrc, avatar_config }: Props) {
    const { scene } = avatar_config;
    const [modal, setModal] = useState<Modal | null>();
    const { setIsTextMode } = useInteractionSetting<ModeSetting>('mode');
    const { micListeners } = useInteractionSetting<MicSetting>('mic');
    const { audioListeners } = useInteractionSetting<AudioSetting>('audio');
    const isSourcesDrawerOpen = useInteractionSettingsStore(
        state => state.isSourcesDrawerOpen,
    );

    // Message updates (especially with the typewriter effect) => this component will re-render unnecessarily
    const {
        addActionProcessor,
        removeActionProcessor,
        sendMessage,
    } = useInteractionContext(InteractionContextAPI);

    const { awaitingResponse, isAgentBusy } = useInteractionContext(
        InteractionContextStatus,
    );

    const awaitingResponseRef = useRefOfState(awaitingResponse);
    const isAgentBusyRef = useRefOfState(isAgentBusy);

    const asrBuffer = useRef('');

    const onMessageRecognised = useCallback(
        (text: string) => {
            if (!awaitingResponseRef.current && !isAgentBusyRef.current) {
                const isMicOn = useInteractionSettingsStore.getState().isMicOn;
                if (isMicOn) {
                    asrBuffer.current += text + ' ';
                } else {
                    sendMessage(text, {
                        message_source:
                            UserMessageSource.CLOUD_RECOGNISED_SPEECH,
                    });
                }
                console.log('buffer', asrBuffer.current);
            } else {
                console.debug(
                    'Skipped recognised speech because avatar was busy',
                );
            }
        },
        [awaitingResponseRef, isAgentBusyRef, sendMessage],
    );

    const sendBuffer = useCallback(
        (micOn: boolean) => {
            if (!micOn && asrBuffer.current.length) {
                sendMessage(asrBuffer.current, {
                    message_source: UserMessageSource.CLOUD_RECOGNISED_SPEECH,
                });
                asrBuffer.current = '';
            }
        },
        [sendMessage],
    );

    const {
        initialiseRapport: _initialiseRapport,
        speakProcessor,
        applyMask,
        toggleAvatarAudio,
        listen,
        disconnect,
    } = useRapport('rapportScene', avatar_config);

    // A bit convoluted, but we need a way for the timeout / error handlers to be provided to the rapport initialisation
    // and they've got buttons that will reinitialise rapport & close the modal, so the recursive invocation patterns is
    // used to make everything DRY
    const initRapport = useCallback(
        (onConnect?: () => void, onError?: (err: any) => void) => {
            const onTtsTimeout = () => {
                disconnect();
                setModal({
                    ...RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
                    actions: [
                        {
                            label: 'Continue with text-chat',
                            onClick: () => {
                                setIsTextMode(true);
                                setModal(null);
                            },
                        },
                        {
                            label: 'Resume with avatar',
                            onClick: () => {
                                const modalLoadingState = {
                                    ...RAPPORT_SESSION_DISCONNECTED_TTS_FAILED_MODAL_TEMPLATE,
                                    actions: [
                                        {
                                            label: 'Continue with text-chat',
                                            disabled: true,
                                        },
                                        {
                                            label: 'Resume with avatar',
                                            isLoading: true,
                                            loadingText: 'Resuming',
                                        },
                                    ],
                                };
                                setModal(modalLoadingState);
                                initRapport(() => setModal(null));
                            },
                        },
                    ],
                });
            };

            const onConnectionError = (err: any) => {
                if (!!err.code && err.code.includes('TIMEOUT')) {
                    setModal({
                        ...RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
                        actions: [
                            {
                                label: 'Finish',
                                onClick: () => {
                                    // this.props.completeStage();
                                    setModal(null);
                                },
                            },
                            {
                                label: 'Rejoin',
                                onClick: () => {
                                    const modalLoadingState = {
                                        ...RAPPORT_SESSION_DISCONNECTED_TIMEOUT_MODAL_TEMPLATE,
                                        actions: [
                                            {
                                                label: 'Finish',
                                                disabled: true,
                                            },
                                            {
                                                label: 'Rejoin',
                                                isLoading: true,
                                                loadingText: 'Rejoining',
                                            },
                                        ],
                                    };
                                    setModal(modalLoadingState);
                                    initRapport(() => {
                                        setModal(null);
                                    });
                                },
                            },
                        ],
                    });
                } else {
                    setModal({
                        ...RAPPORT_SESSION_COULD_NOT_INITIALISE,
                        actions: [
                            {
                                label: 'Continue with text chat',
                                onClick: () => {
                                    setIsTextMode(true);
                                    setModal(null);
                                },
                            },
                            {
                                label: 'Try again',
                                onClick: () => {
                                    const modalLoadingState = {
                                        ...RAPPORT_SESSION_COULD_NOT_INITIALISE,
                                        actions: [
                                            {
                                                label:
                                                    'Continue with text-chat',
                                                disabled: true,
                                            },
                                            {
                                                label: 'Try again',
                                                isLoading: true,
                                                loadingText: 'Loading',
                                            },
                                        ],
                                    };
                                    setModal(modalLoadingState);
                                    initRapport(() => {
                                        setModal(null);
                                    });
                                },
                            },
                        ],
                    });
                }
            };

            const isMicOn = useInteractionSettingsStore.getState().isMicOn;
            _initialiseRapport(
                () => {
                    onConnect && onConnect();
                    micListeners.add(listen);
                    micListeners.add(sendBuffer);
                    audioListeners.add(toggleAvatarAudio);
                    applyMask(VIDEO_CLIP_PATH);
                    addActionProcessor('audio', (action, activeStage) => {
                        return speakProcessor(
                            action,
                            activeStage,
                            10,
                            onTtsTimeout,
                        );
                    });
                },
                err => {
                    onError && onError(err);
                    onConnectionError(err);
                    removeActionProcessor('audio');
                    audioListeners.delete(toggleAvatarAudio);
                    micListeners.delete(listen);
                    micListeners.delete(sendBuffer);
                },
                onMessageRecognised,
                { micMuted: !isMicOn },
            );
        },
        // These are all methods and are expected not to change
        [
            _initialiseRapport,
            onMessageRecognised,
            disconnect,
            setIsTextMode,
            micListeners,
            listen,
            sendBuffer,
            addActionProcessor,
            speakProcessor,
            removeActionProcessor,
        ],
    );

    useEffect(() => {
        console.debug('Avatar wrapper load: initialising rapport');
        initRapport();

        return () => {
            console.debug('Avatar wrapper un-load: disconnecting rapport');
            removeActionProcessor('audio');
            micListeners.delete(listen);
            disconnect();
        };
    }, [listen, micListeners, disconnect, initRapport, removeActionProcessor]);

    return (
        <Fragment>
            {backgroundSrc && !backgroundSrc.startsWith('#') ? (
                <Box
                    w='100%'
                    h='100%'
                    backgroundImage={backgroundSrc}
                    backgroundSize='cover'
                    backgroundPosition='bottom'
                />
            ) : (
                <Box
                    w='100%'
                    h='100%'
                    backgroundColor={backgroundSrc || DEFAULT_BACKGROUND_COLOR}
                />
            )}
            {isDesktop && (
                <Box
                    h='100%'
                    w={{ base: '100%', xl: '60%' }}
                    position='fixed'
                    left={{
                        base: '-350px',
                        xl: isSourcesDrawerOpen ? '0px' : '100px',
                    }}
                    bottom='0px'
                >
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <rapport-scene
                        id='rapportScene'
                        base-url='https://api.rapport.cloud/api/v1/'
                        project-id={scene.projectId}
                        project-token={scene.projectToken}
                        ai-user-id={scene.aiUserId}
                        lobby-zone-id={scene.lobbyZoneId}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Box>
            )}
            {isMobile && (
                <Center h='90%' w='100%' position='fixed' bottom='0'>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <rapport-scene
                        id='rapportScene'
                        base-url='https://api.rapport.cloud/api/v1/'
                        project-id={scene.projectId}
                        project-token={scene.projectToken}
                        ai-user-id={scene.aiUserId}
                        lobby-zone-id={scene.lobbyZoneId}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    />
                </Center>
            )}
            <ModalWithNoInitialFocus
                isOpen={!!modal}
                closeOnOverlayClick={false}
                onClose={() => setModal(null)}
                {...modal?.modalProps}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{modal?.title}</ModalHeader>
                    <ModalBody>{modal?.content}</ModalBody>
                    <ModalFooter>
                        <HStack>
                            {modal?.actions.map(({ label, ...props }) => (
                                <Button
                                    key={label}
                                    colorScheme='brand.black'
                                    {...props}
                                >
                                    {label}
                                </Button>
                            ))}
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </ModalWithNoInitialFocus>
        </Fragment>
    );
}
