import React from 'react';
import { Box } from '@chakra-ui/react';

export default function IconSlash() {
    return (
        <Box
            position='absolute'
            top='50%'
            left='50%'
            transform='translate(-50%, -50%) rotate(45deg)'
            h='3px'
            w='30px'
            backgroundColor='black'
            border='1px solid white'
        />
    );
}
