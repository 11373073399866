import { useEffect } from 'react';

interface Props {
    keyName: string;
    onPush: () => void;
    onRelease: () => void;
}

const isInputElementActive = () =>
    ['input', 'textarea', 'select'].includes(
        document.activeElement?.tagName.toLowerCase() || '',
    );

const isTargetKey = (keyboardEvent: KeyboardEvent, key: string) => {
    if (isInputElementActive()) return;
    if (key === 'space') {
        return keyboardEvent.key === key || keyboardEvent.code === 'Space';
    } else {
        return keyboardEvent.key === key;
    }
};
const KeyControl = ({ keyName, onPush, onRelease }: Props) => {
    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (!isTargetKey(event, keyName) || event.repeat) return;
            console.log(event);
            onPush && onPush();
        };

        const onKeyUp = (event: KeyboardEvent) => {
            if (!isTargetKey(event, keyName)) return;
            console.log(event);
            onRelease && onRelease();
        };

        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);

        return () => {
            window.removeEventListener('keyup', onKeyDown);
            window.removeEventListener('keydown', onKeyUp);
        };
    }, [keyName, onPush, onRelease]);

    return null;
};

export default KeyControl;
