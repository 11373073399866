import React, { Fragment } from 'react';
import AvatarWrapper, {
    Props as AvatarProps,
} from '../../organisms/AvatarWrapper/AvatarWrapper';
import AvatarMobileTemplate from '../AvatarMobileTemplate/AvatarMobileTemplate';
import { isMobile, isDesktop } from 'react-device-detect';
import AvatarDesktopTemplate from '../AvatarDesktopTemplate/AvatarDesktopTemplate';
import { Props as ChatProps } from '../../organisms/Chat/Chat';

interface Props extends ChatProps, AvatarProps {}

export default function AvatarTemplate(props: Props) {
    return (
        <Fragment>
            <AvatarWrapper {...props} />
            {isDesktop && <AvatarDesktopTemplate {...props} />}
            {isMobile && <AvatarMobileTemplate {...props} />}
        </Fragment>
    );
}
