import { create } from 'zustand';

export type AudioSetting = {
    isAudioOn: boolean;
    toggleAudio: () => void;
    audioListeners: Set<(isOn: boolean) => void>;
};

export type MicSetting = {
    isMicOn: boolean;
    toggleMic: () => void;
    turnMicOn: () => void;
    turnMicOff: () => void;
    micListeners: Set<(isOn: boolean) => void>;
};

export type CaptionSetting = {
    isCaptionsOn: boolean;
    toggleCaptions: () => void;
};

export type ModeSetting = {
    isTextMode: boolean;
    toggleTextMode: () => void;
    setIsTextMode: (isTextMode: boolean) => void;
};

type AttachmentToDisplay = {
    title?: string;
    description?: string;
    url: string;
};

export type AttachmentSetting = {
    attachmentToDisplay: AttachmentToDisplay | undefined;
    setAttachment: (attachment: AttachmentToDisplay) => void;
};

export type ModalSetting = {
    isModalOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
};

export type SourcesSidePanelSetting = {
    isSourcesDrawerOpen: boolean;
    openDrawer: () => void;
    closeDrawer: () => void;
};

type InteractionSettingsStore = AudioSetting &
    CaptionSetting &
    ModeSetting &
    MicSetting &
    ModalSetting &
    AttachmentSetting &
    SourcesSidePanelSetting;

type InteractionSettingStoreMember = keyof InteractionSettingsStore;

type InteractionSetting =
    | 'audio'
    | 'captions'
    | 'mode'
    | 'mic'
    | 'modal'
    | 'attachment'
    | 'drawer';

export const useInteractionSettingsStore = create<InteractionSettingsStore>(
    (set, get) => ({
        audioListeners: new Set(),
        isAudioOn: true,
        toggleAudio: () =>
            set(state => {
                const isAudioOn = !state.isAudioOn;
                get().audioListeners.forEach(listener => listener(isAudioOn));
                return {
                    isAudioOn,
                };
            }),

        micListeners: new Set(),
        isMicOn: false,
        toggleMic: () =>
            set(state => {
                const isMicOn = !state.isMicOn;
                get().micListeners.forEach(listener => listener(isMicOn));
                return { isMicOn };
            }),
        turnMicOn: () => {
            console.log('[MIC On]');
            set(() => ({ isMicOn: true }));
            get().micListeners.forEach(listener => listener(true));
        },
        turnMicOff: () => {
            console.log('[MIC Off]');
            set(() => ({ isMicOn: false }));
            get().micListeners.forEach(listener => listener(false));
        },

        isCaptionsOn: false,
        toggleCaptions: () =>
            set(state => ({ isCaptionsOn: !state.isCaptionsOn })),

        isTextMode: false,
        toggleTextMode: () => set(state => ({ isTextMode: !state.isTextMode })),
        setIsTextMode: (isTextMode: boolean) => set({ isTextMode }),

        isModalOpen: false,
        openModal: () => set(() => ({ isModalOpen: true })),
        closeModal: () => set(() => ({ isModalOpen: false })),

        attachmentToDisplay: undefined,
        setAttachment: attachment =>
            set(() => ({ attachmentToDisplay: attachment })),

        isSourcesDrawerOpen: false,
        openDrawer: () => set(() => ({ isSourcesDrawerOpen: true })),
        closeDrawer: () => set(() => ({ isSourcesDrawerOpen: false })),
    }),
);

// Pretty sure there's a better way to do this, but don't have the time to think of a clearer API
export const useInteractionSetting = <T>(setting: InteractionSetting): T => {
    return useInteractionSettingsStore(state => {
        const keys = Object.keys(state).filter(key =>
            key.toLowerCase().includes(setting),
        ) as InteractionSettingStoreMember[];

        return Object.fromEntries(keys.map(key => [key, state[key]])) as T;
    });
};

export default useInteractionSettingsStore;
