import React, { useState } from 'react';
import { Field, Form, Formik, FormikValues } from 'formik';
import stringUtils from '../../utils/stringUtils';
import ModalOpener from '../generic/ModalOpener';
import TermsOfServiceModal from '../modals/TermsOfServiceModal';
import PrivacyPolicyModal from '../modals/PrivacyPolicyModal';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Text,
    VStack,
} from '@chakra-ui/react';
import ROUTES from '../../consts/routes';
import { keycloak } from '../../services';

interface Props {
    onSubmit: (
        values: FormikValues,
        onError: (errorMessage?: string) => void,
    ) => void;
    isPurchasing: boolean;
}

const initialValues = {
    firstName: '',
    lastName: '',
    registerEmail: '',
    registerPassword: '',
    termsAccepted: false,
};

const validateFirstName = (value: string) =>
    !value ? 'First name is required' : undefined;

const validateLastName = (value: string) =>
    !value ? 'Last name is required' : undefined;

export const validateEmail = (value: string) => {
    const validEmailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!value) {
        return 'Email required';
    } else if (!validEmailRegex.test(value)) {
        return 'Invalid email address';
    }
};

export const validatePassword = (value: string) => {
    const specialCharacterRegex = /[!@#£$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

    if (!value) {
        return 'Password required';
    } else if (value.length < 8) {
        return 'Password must be at least 8 characters';
    } else if (value.length > 72) {
        return 'Password must be less than 72 characters';
    } else if (!specialCharacterRegex.test(value)) {
        return 'Password must contain at least one special character';
    }
};

const BaseRegistrationForm: React.FC<Props> = ({ onSubmit, isPurchasing }) => {
    const [serverError, setServerError] = useState<null | string>(null);
    const [showPassword, setShowPassword] = useState(false);

    const submitWrapper = (
        values: FormikValues,
        { setSubmitting }: { setSubmitting: (val: boolean) => void },
    ): void => {
        setSubmitting(true);
        setServerError(null);

        const onError = (errorMessage?: string): void => {
            errorMessage && setServerError(errorMessage);
            setSubmitting(false);
        };

        onSubmit(values, onError);
    };

    return (
        <Box>
            {serverError && (
                <Alert status='error' mb={5}>
                    <AlertIcon />
                    <AlertTitle>
                        {stringUtils.capitalizeFirstLetter(serverError)}
                    </AlertTitle>
                </Alert>
            )}
            <Formik initialValues={initialValues} onSubmit={submitWrapper}>
                {({ values, errors, touched, isSubmitting }): JSX.Element => (
                    <Form>
                        <VStack spacing={4} align='flex-start'>
                            <Flex w='100%'>
                                <FormControl
                                    isInvalid={
                                        touched.firstName && !!errors.firstName
                                    }
                                    isRequired
                                    mr={2}
                                >
                                    <FormLabel htmlFor='firstName'>
                                        First name
                                    </FormLabel>
                                    <Field
                                        as={Input}
                                        type='input'
                                        id='firstName'
                                        name='firstName'
                                        placeholder='First name'
                                        validate={validateFirstName}
                                    />
                                    <FormErrorMessage>
                                        {errors.firstName}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                    isInvalid={
                                        touched.lastName && !!errors.lastName
                                    }
                                    isRequired
                                    ml={2}
                                >
                                    <FormLabel htmlFor='lastName'>
                                        Last name
                                    </FormLabel>
                                    <Field
                                        as={Input}
                                        type='input'
                                        id='lastName'
                                        name='lastName'
                                        placeholder='Last name'
                                        validate={validateLastName}
                                    />
                                    <FormErrorMessage>
                                        {errors.lastName}
                                    </FormErrorMessage>
                                </FormControl>
                            </Flex>

                            <FormControl
                                isInvalid={
                                    touched.registerEmail &&
                                    !!errors.registerEmail
                                }
                                isRequired
                            >
                                <FormLabel htmlFor='registerEmail'>
                                    Email address
                                </FormLabel>
                                <Field
                                    as={Input}
                                    type='email'
                                    id='registerEmail'
                                    name='registerEmail'
                                    placeholder='Email address'
                                    validate={validateEmail}
                                />
                                <FormErrorMessage>
                                    {errors.registerEmail}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl
                                isInvalid={
                                    touched.registerPassword &&
                                    !!errors.registerPassword
                                }
                                isRequired
                            >
                                <FormLabel htmlFor='registerPassword'>
                                    Password
                                </FormLabel>
                                <InputGroup>
                                    <Field
                                        as={Input}
                                        id='registerPassword'
                                        name='registerPassword'
                                        type={
                                            showPassword ? 'input' : 'password'
                                        }
                                        placeholder='Password'
                                        validate={validatePassword}
                                        pr='4.5rem'
                                    />
                                    <InputRightElement width='4.5rem'>
                                        <Button
                                            h='1.75rem'
                                            px='0.75rem'
                                            size='sm'
                                            onClick={() =>
                                                setShowPassword(old => !old)
                                            }
                                            colorScheme='brand.black'
                                        >
                                            {showPassword ? 'Hide' : 'Show'}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                    {errors.registerPassword}
                                </FormErrorMessage>
                            </FormControl>

                            <FormControl isRequired>
                                <Field
                                    as={Checkbox}
                                    id='termsAccepted'
                                    name='termsAccepted'
                                    colorScheme='brand.black'
                                >
                                    {'I agree to the '}
                                    <ModalOpener Modal={TermsOfServiceModal}>
                                        <Link onClick={e => e.preventDefault()}>
                                            terms of service
                                        </Link>
                                    </ModalOpener>
                                    {' and '}
                                    <ModalOpener Modal={PrivacyPolicyModal}>
                                        <Link onClick={e => e.preventDefault()}>
                                            {' '}
                                            privacy policy{' '}
                                        </Link>
                                    </ModalOpener>
                                </Field>
                            </FormControl>

                            <Button
                                w='100%'
                                colorScheme='brand.black'
                                type='submit'
                                disabled={
                                    isSubmitting ||
                                    !Object.values(values).every(v => !!v) ||
                                    Object.values(errors).some(e => !!e)
                                }
                                isLoading={isSubmitting}
                                loadingText='Registering'
                            >
                                {isPurchasing
                                    ? 'Sign up and checkout'
                                    : 'Sign up'}
                            </Button>
                        </VStack>
                    </Form>
                )}
            </Formik>
            <Text mt={5}>
                Already have an account?{' '}
                <Link
                    href={ROUTES.LOGIN}
                    onClick={() => {
                        window.history.pushState(
                            window.history.state,
                            document.title,
                            document.location.href,
                        );
                        keycloak.login();
                    }}
                >
                    Login
                </Link>
            </Text>
        </Box>
    );
};

export default BaseRegistrationForm;
