import React, { Fragment, Suspense, useEffect, useMemo } from 'react';
import {
    AspectRatio,
    Box,
    Button,
    Drawer,
    DrawerContent,
    DrawerOverlay,
    Flex,
    HStack,
    Spacer,
    Text,
    useDisclosure,
    VStack,
    useMediaQuery,
} from '@chakra-ui/react';
import ExpandableText from '../../../components/generic/ExpandableText';
import { ActivityImage } from '../../../components/activities/ActivityIcon';
import getHumanReadableFromDate from '../../../utils/dateUtils';
import ModalOpener from '../../../components/generic/ModalOpener';
import InsightsFeedbackModal from '../../../components/modals/InsightsFeedbackModal';
import { ModalProps } from '../../../components/modals/types';
import {
    isExercise,
    isReading,
    isVideo,
    isWritingAssignment,
} from '../../../utils/activityUtils';
import ViewWritingModal from '../../../components/modals/ViewWritingModal';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../../consts/routes';
import WritingAssignmentLabel from './WritingAssignmentLabel';
import BestAttempt from './BestAttempt';
import ActivityHeading from './ActivityHeading';
import IndexNumber from './IndexNumber';
import InstructionModal from './InstructionModal';
import useScrollRef from '../../../hooks/useScrollRef';
import { hasAttemptInProgress, useCancelAttemptMutation } from './utils';
import { popSearchParam } from '../../../utils/historyUtils';
import useExternalIDPRoutePrefix from '../../../hooks/useExternalIDPRoutePrefix';

const ActivityPageContent = React.lazy(() =>
    import(
        '../../../components/activities/ActivityPageContent/ActivityPageContent'
    ),
);

const ActivityCard: React.FC<{
    activity: Activity;
    studentActivity?: StudentActivity | null;
    index: number;
    course: Course;
    isHighlighted?: boolean;
    isActive?: boolean;
    onStartActivityMode: () => void;
    onCloseActivityMode: () => void;
}> = ({
    activity,
    studentActivity,
    index,
    course,
    isHighlighted,
    isActive,
    onCloseActivityMode,
    onStartActivityMode,
}) => {
    const history = useHistory();
    const routePrefix = useExternalIDPRoutePrefix();
    const scrollRef = useScrollRef(!!isHighlighted);

    const [screenWidthGreatherThan950] = useMediaQuery('(min-width: 950px)');

    const {
        isOpen: isInstructionModalOpen,
        onOpen: openInstructionsModal,
        onClose: closeInstructionsModal,
    } = useDisclosure();

    const startActivity = (skipInstructions = false) => {
        if (!skipInstructions && activity.before_start_instructions) {
            openInstructionsModal();
        } else {
            // onStartActivityMode();
            history.replace({
                // Use the routeprefix to prevent a redirect from App, which blocks the activity drawer from opening
                pathname: `${routePrefix}/${ROUTES.COURSES}/${course.id}/${ROUTES.ACTIVITY}/${activity.id}/player`,
                search: history.location.search,
            });
        }
    };

    const closeActivity = () => {
        history.replace({ search: '' }); // Clears no-webgl
        onCloseActivityMode();
    };

    const cancelAttemptMutation = useCancelAttemptMutation();

    const completedAttempts = useMemo(
        () =>
            studentActivity?.attempts.filter(a => !!a.completed_datetime) || [],
        [studentActivity],
    );

    const hasRemainingAttemptsToday =
        !studentActivity ||
        (studentActivity.has_remaining_attempts_today ?? true);

    const hasRemainingTotalAttempts =
        !activity.config.total_attempt_limit ||
        !studentActivity ||
        studentActivity.total_attempts_remaining === null ||
        studentActivity.total_attempts_remaining > 0;

    const _InsightsModal = useMemo(() => {
        const _Modal = (props: ModalProps) => (
            <InsightsFeedbackModal
                attempts={completedAttempts || []}
                activity={activity}
                course={course}
                {...props}
            />
        );
        _Modal.display_name = 'InsightsModal';
        return _Modal;
    }, [activity, completedAttempts, course]);

    const _ViewWritingModal = useMemo(() => {
        const _Modal = (props: ModalProps) => (
            <ViewWritingModal
                title={
                    <WritingAssignmentLabel
                        writingAssignment={activity.writing_assignment}
                    />
                }
                rawContent={activity.writing_assignment?.content}
                {...props}
            />
        );
        _Modal.display_name = 'ViewWritingModal';
        return _Modal;
    }, [activity]);

    useEffect(() => {
        const shouldStartActivity = new URLSearchParams(
            history.location.search,
        ).get('startActivity');
        if (shouldStartActivity && isHighlighted) {
            popSearchParam('startActivity', false, history);
            startActivity(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <HStack
                id={`activity-card_${activity.id}`}
                ref={scrollRef}
                borderRadius='lg'
                bg='white'
                px={5}
                py={6}
                alignItems='top'
                spacing={4}
            >
                <HStack spacing={4} alignSelf='start'>
                    <IndexNumber
                        activity={activity}
                        index={index}
                        isActive={isHighlighted}
                    />
                    {screenWidthGreatherThan950 && (
                        <Box flex='0 0 auto'>
                            <AspectRatio ratio={3 / 2} width='260px'>
                                <ActivityImage activity={activity} />
                            </AspectRatio>
                        </Box>
                    )}
                </HStack>
                <Flex flex={1} alignSelf='stretch' direction='column'>
                    <VStack alignItems='start'>
                        <Flex alignSelf='stretch' alignItems='center' mb={1}>
                            <ActivityHeading
                                activity={activity}
                                studentActivity={studentActivity}
                            />
                        </Flex>
                        <ExpandableText noOfLines={2} lineHeight='1.3em'>
                            <Box
                                lineHeight='1.3em'
                                mb='-1rem' // Countering the default 1rem padding on p elements
                                dangerouslySetInnerHTML={{
                                    __html: activity.description,
                                }}
                            />
                        </ExpandableText>
                    </VStack>

                    <Spacer />
                    {!!studentActivity?.attempts.length && (
                        <BestAttempt attempts={studentActivity.attempts} />
                    )}
                    {(isVideo(activity) ||
                        isReading(activity) ||
                        !!activity.config.pass_threshold) &&
                        studentActivity?.completed_datetime &&
                        `Completed at ${getHumanReadableFromDate(
                            studentActivity?.completed_datetime,
                        )}`}
                    {isWritingAssignment(activity) && (
                        <WritingAssignmentLabel
                            writingAssignment={activity.writing_assignment}
                        />
                    )}
                    <HStack mt={2}>
                        {!activity.writing_assignment?.submitted_at && ( // We don't support reattempts for writings
                            <Button
                                colorScheme='brand.black'
                                _hover={{ color: 'white' }}
                                onClick={() => startActivity()}
                                disabled={
                                    cancelAttemptMutation.isLoading ||
                                    (isExercise(activity) &&
                                        !hasAttemptInProgress(
                                            activity,
                                            studentActivity,
                                        ) &&
                                        (!hasRemainingAttemptsToday ||
                                            !hasRemainingTotalAttempts))
                                }
                            >
                                {hasAttemptInProgress(activity, studentActivity)
                                    ? 'Resume'
                                    : studentActivity?.attempts.length
                                    ? 'Reattempt'
                                    : 'Start activity'}
                            </Button>
                        )}
                        {!activity.writing_assignment &&
                        hasAttemptInProgress(activity, studentActivity) && ( // We don't support cancellations for writings
                                <Button
                                    variant='outline'
                                    colorScheme='brand.black'
                                    onClick={() =>
                                        cancelAttemptMutation.mutate({
                                            course,
                                            activity,
                                            attempt: studentActivity!.attempts.find(
                                                a =>
                                                    a.completed_datetime ===
                                                    null,
                                            )!,
                                        })
                                    }
                                    isLoading={cancelAttemptMutation.isLoading}
                                    loadingText='Cancelling'
                                >
                                    Cancel
                                </Button>
                            )}
                        {completedAttempts.length > 0 && (
                            <ModalOpener Modal={_InsightsModal}>
                                <Button
                                    variant='outline'
                                    colorScheme='brand.black'
                                >
                                    View feedback
                                </Button>
                            </ModalOpener>
                        )}
                        {isWritingAssignment(activity) &&
                            (activity.writing_assignment?.submitted_at ||
                                activity.writing_assignment
                                    ?.saved_draft_at) && (
                                <ModalOpener Modal={_ViewWritingModal}>
                                    <Button
                                        variant='outline'
                                        colorScheme='brand.black'
                                    >
                                        View
                                    </Button>
                                </ModalOpener>
                            )}
                    </HStack>
                    {isExercise(activity) &&
                        !hasAttemptInProgress(activity, studentActivity) &&
                        (!hasRemainingTotalAttempts ||
                            !hasRemainingAttemptsToday) && (
                            <Text mb={0} color='red.500'>
                                {!hasRemainingTotalAttempts
                                    ? 'No attempts remaining'
                                    : 'No attempts remaining today'}
                            </Text>
                        )}
                </Flex>
            </HStack>

            <Drawer
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={false}
                closeOnEsc={false}
                placement='bottom'
                isOpen={!!isActive}
                onClose={closeActivity}
                size='full'
            >
                <DrawerOverlay />
                <DrawerContent>
                    <Suspense fallback={null}>
                        <ActivityPageContent
                            courseId={course.id.toString()}
                            activityId={activity.id.toString()}
                            onClose={closeActivity}
                        />
                    </Suspense>
                </DrawerContent>
            </Drawer>

            <InstructionModal
                onStart={() => startActivity(true)}
                show={isInstructionModalOpen}
                onClose={closeInstructionsModal}
                startButtonText={
                    hasAttemptInProgress(activity, studentActivity)
                        ? 'Resume'
                        : 'Start'
                }
            >
                <div
                    dangerouslySetInnerHTML={{
                        __html: activity.before_start_instructions!,
                    }}
                />
            </InstructionModal>
        </Fragment>
    );
};

export default ActivityCard;
