import { useCallback, useMemo, useState } from 'react';

export enum InteractionStates {
    start = 'start',

    disconnected = 'disconnected',
    connecting = 'connecting',
    connected = 'connected',

    ready = 'ready',
    waiting = 'waiting',
    processing = 'processing', // i.e. speaking or typing

    queue = 'queue',
    complete = 'complete',
}

export enum InteractionActions {
    initialise = 'initialise',

    connect = 'connect',
    connect_success = 'connect_success',
    connect_failed = 'connect_failed',
    disconnect = 'disconnect',

    send = 'send',
    receive = 'receive',

    process = 'process',
    processed = 'processed',

    completed = 'completed',
}

type InteractionState = keyof typeof InteractionStates;
type InteractionAction = keyof typeof InteractionActions;

type StateMachine = {
    [state in InteractionStates]: {
        [action in InteractionActions]?: InteractionState;
    };
};

export const InteractionStateMachine: StateMachine = {
    [InteractionStates.start]: {
        [InteractionActions.initialise]: InteractionStates.disconnected,
    },
    [InteractionStates.disconnected]: {
        [InteractionActions.connect]: InteractionStates.connecting,
    },
    [InteractionStates.connecting]: {
        [InteractionActions.connect_success]: InteractionStates.connected,
        [InteractionActions.connect_failed]: InteractionStates.disconnected,
    },
    [InteractionStates.connected]: {
        [InteractionActions.send]: InteractionStates.waiting,
        [InteractionActions.disconnect]: InteractionStates.disconnected,
        [InteractionActions.completed]: InteractionStates.complete,
    },
    [InteractionStates.ready]: {
        [InteractionActions.send]: InteractionStates.waiting,
        [InteractionActions.receive]: InteractionStates.queue,
        [InteractionActions.disconnect]: InteractionStates.disconnected,
        [InteractionActions.completed]: InteractionStates.complete,
    },
    [InteractionStates.waiting]: {
        [InteractionActions.receive]: InteractionStates.queue,
        [InteractionActions.disconnect]: InteractionStates.disconnected,
        [InteractionActions.completed]: InteractionStates.complete,
    },
    [InteractionStates.queue]: {
        [InteractionActions.process]: InteractionStates.processing,
        [InteractionActions.processed]: InteractionStates.ready,
        [InteractionActions.disconnect]: InteractionStates.disconnected,
        [InteractionActions.completed]: InteractionStates.complete,
    },
    // This is when the actions are queued
    [InteractionStates.processing]: {
        // This is when both isAvatarSpeaking and isTyping are finished
        [InteractionActions.processed]: InteractionStates.ready,
        [InteractionActions.disconnect]: InteractionStates.disconnected,
        [InteractionActions.completed]: InteractionStates.complete,
    },
    [InteractionStates.complete]: {},
};

const AgentBusyStateSet = new Set<InteractionState>([
    InteractionStates.queue,
    InteractionStates.processing,
]);

const AwaitingResponseStateSet = new Set<InteractionState>([
    InteractionStates.connecting,
    InteractionStates.waiting,
]);

const DisabledStateSet = new Set<InteractionState>([
    InteractionStates.start,
    InteractionStates.disconnected,
    InteractionStates.complete,
]);

export const useInteractionAgent = () => {
    const [status, setStatus] = useState<InteractionState>(
        InteractionStates.start,
    );

    const act = useCallback((action: InteractionAction) => {
        setStatus(previousStatus => {
            const newState = InteractionStateMachine[previousStatus][action];
            console.log(
                `[DEBUG STATE MACHINE]: (${new Date().toISOString()}) ${previousStatus} --- ${action} ---> ${newState}`,
            );
            if (!newState) {
                return previousStatus;
            }

            return newState;
        });
    }, []);

    return useMemo(
        () => ({
            status,
            act,
            // isDisabled is when the relevant components are being initialised.
            isDisabled: DisabledStateSet.has(status),
            // awaitingResponse is when a message has been sent to the server, and we are waiting for the first response to be received
            awaitingResponse: AwaitingResponseStateSet.has(status),
            // isAgentBusy represents the state when the avatar is speaking, or the text is being updated.
            isAgentBusy: AgentBusyStateSet.has(status),
        }),
        [status, act],
    );
};
