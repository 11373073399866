import React from 'react';
import { Button } from '@chakra-ui/react';

interface Props {
    citationId: number;
    clickable?: boolean;
    onClick?: () => void;
}

export default function CitationButton({
    citationId,
    clickable = true,
    onClick,
}: Props) {
    return (
        <Button
            borderRadius='full'
            color='white'
            backgroundColor='dark.450'
            fontSize='xs'
            p='0px'
            minW='16px'
            boxSize='16px'
            mx='3px'
            _hover={{ cursor: clickable ? 'pointer' : 'none' }}
            onClick={clickable ? onClick : undefined}
        >
            {citationId}
        </Button>
    );
}
