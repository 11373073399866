import { theme } from './theme';

export const scrollBarStyles = {
    '::-webkit-scrollbar': {
        width: '8px',
    },
    '::-webkit-scrollbar-thumb': {
        background: 'gray.600',
        borderRadius: '8px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: 'gray.600',
    },
    '::-webkit-scrollbar-track': {
        background: 'gray.200',
        borderRadius: '8px',
    },
};

export const navbarToggleStyles = {
    container: {
        backgroundColor: 'dark.700',
        border: '1px solid',
        borderColor: 'dark.500',
        borderRadius: '10px',
        padding: '5px',
    },
};

export const getChatStyles = (isTextMode: boolean) => {
    const chatStyles = {
        textMode: {
            chatContainer: {
                h: '100%',
                paddingBottom: '10px',
            },
            historyContainer: {
                w: ['90%', '70%', '70%', '60%', '50%', '45%'],
            },
            avatarMessage: {
                backgroundColor: 'transparent',
                p: '0px 20px 0px 0px',
            },
            userMessage: {
                backgroundColor: theme.colors.dark['500'],
            },
        },
        avatar: {
            chatContainer: {
                h: '90%',
            },
            historyContainer: {
                w: ['90%', '80%', '70%', '70%', '90%', '80%'],
            },
            avatarMessage: {
                backgroundColor: theme.colors.dark['500'],
                p: '10px',
            },
            userMessage: {
                backgroundColor: theme.colors.dark['450'],
            },
        },
    };

    return isTextMode ? chatStyles.textMode : chatStyles.avatar;
};
