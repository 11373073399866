import React, { Fragment, useContext, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import CourseCard from '../courses/CourseCard';
import { useQuery } from '@tanstack/react-query';
import StudentFeaturesContext from '../contexts/StudentFeaturesContext';
import ROUTES from '../../consts/routes';
import { Link, useHistory } from 'react-router-dom';
import CentredComponent from '../../hocs/CentredComponent';
import { useMediaQuery } from 'react-responsive';
import TrophyIcon from '../../images/trophy.svg';
import { useMixpanel } from '../contexts/MixpanelContext';
import { moocAPI } from '../../services';
import { ModalProps } from './types';
import {
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
} from '@chakra-ui/react';
import { ModalWithNoInitialFocus } from '../overlays';

interface Props extends ModalProps {
    course: Course;
    show: boolean;
    onClose: () => void;
}

const CourseCompleteModal: React.FC<Props> = ({
    show,
    course,
    onClose,
}: Props) => {
    const isLg = useMediaQuery({ minWidth: '992px' });
    const history = useHistory();
    const mixpanel = useMixpanel();

    const { trialData } = useContext(StudentFeaturesContext);

    const { data: recommendedCourse } = useQuery(
        ['courses', 'available'],
        (): Promise<Course[]> => moocAPI.get('courses/?status=available'),
        {
            select: courses => (courses.length ? courses[0] : null),
            enabled: !show,
        },
    );

    const navigateToCourse = (course: Course): void =>
        history.push({ pathname: `/${ROUTES.COURSES}/${course.id}` });
    const showRecommendedCourse = trialData.is_trial && recommendedCourse;

    useEffect(() => {
        if (show) {
            mixpanel?.track('show_course_complete_modal', {
                recommendedCourse: showRecommendedCourse,
            });
        }
        // eslint-disable-next-line
    }, [show]);

    return (
        <ModalWithNoInitialFocus isOpen={show} onClose={onClose} size='4xl'>
            <ModalOverlay />
            <ModalContent my={3}>
                <ModalBody>
                    <div className='my-3'>
                        <CentredComponent className='my-2'>
                            <img src={TrophyIcon} width='60px' alt='trophy' />
                        </CentredComponent>
                        <CentredComponent>
                            <Col className='text-center mt-3'>
                                <h2 className='h2'>Congratulations!</h2>
                            </Col>
                        </CentredComponent>
                        <CentredComponent className='mb-4'>
                            <Col
                                className='text-center'
                                style={{ fontSize: '1.4em' }}
                            >
                                You&apos;ve now completed{' '}
                                <Link to={`/${ROUTES.COURSES}/${course.id}`}>
                                    <strong>{course.title}</strong>
                                </Link>
                            </Col>
                        </CentredComponent>
                    </div>
                    {(showRecommendedCourse || course.achievements) && (
                        <CentredComponent className='align-items-stretch'>
                            <Col lg={6}>
                                <Card className='border-0 h-100'>
                                    <Card.Body className='d-flex flex-column'>
                                        <div
                                            className='mb-lg-4'
                                            style={{ fontWeight: 'bold' }}
                                        >
                                            <div
                                                className='mb-3 h5'
                                                style={{ fontSize: '1.4rem' }}
                                            >
                                                Course grade
                                            </div>
                                            <CentredComponent
                                                style={{ fontSize: '3.2rem' }}
                                            >
                                                {course.student_course?.score}%
                                            </CentredComponent>
                                        </div>
                                        {showRecommendedCourse &&
                                            course.achievements && (
                                                <div>
                                                    {course.achievements.map(
                                                        (achievement, i) => (
                                                            <Row
                                                                key={i}
                                                                noGutters
                                                                className='mb-2'
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                }}
                                                            >
                                                                <Col
                                                                    xs='auto'
                                                                    className='mr-2'
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faCheckCircle
                                                                        }
                                                                        color='green'
                                                                        display='inline-block'
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    {
                                                                        achievement
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        ),
                                                    )}
                                                </div>
                                            )}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={6} className={isLg ? 'border-left' : ''}>
                                <Card className='border-0 h-100'>
                                    <Card.Body className='d-flex flex-column'>
                                        {showRecommendedCourse ? (
                                            <Fragment>
                                                <div
                                                    className='mb-3 h5'
                                                    style={{
                                                        fontSize: '1.4rem',
                                                    }}
                                                >
                                                    Where to next?
                                                </div>
                                                <CentredComponent>
                                                    <Col
                                                        xs='auto'
                                                        className='ml-auto mr-auto'
                                                        onClick={() =>
                                                            mixpanel?.track(
                                                                'course_complete_modal_cta_click',
                                                                {
                                                                    cta:
                                                                        'recommended-course-card',
                                                                },
                                                            )
                                                        }
                                                    >
                                                        <CourseCard
                                                            course={{
                                                                ...recommendedCourse!,
                                                                sub_title: '',
                                                            }}
                                                            size='sm'
                                                        />
                                                    </Col>
                                                </CentredComponent>
                                            </Fragment>
                                        ) : (
                                            <div className='d-flex h-100 align-items-center'>
                                                <div>
                                                    {course.achievements.map(
                                                        (achievement, i) => (
                                                            <Row
                                                                key={i}
                                                                className='mb-2'
                                                                style={{
                                                                    fontSize:
                                                                        '1.1rem',
                                                                }}
                                                            >
                                                                <Col
                                                                    xs='auto'
                                                                    className='mr-2'
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faCheckCircle
                                                                        }
                                                                        color='green'
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    {
                                                                        achievement
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        ),
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </CentredComponent>
                    )}
                </ModalBody>
                <ModalFooter justifyContent='center'>
                    <Button
                        onClick={
                            showRecommendedCourse
                                ? (): void => {
                                      mixpanel?.track(
                                          'course_complete_modal_cta_click',
                                          {
                                              cta: 'recommended-course-btn',
                                          },
                                      );
                                      onClose();
                                      navigateToCourse(recommendedCourse!);
                                  }
                                : (): void => {
                                      mixpanel?.track(
                                          'course_complete_modal_cta_click',
                                          { cta: 'review-course' },
                                      );
                                      onClose();
                                      navigateToCourse(course);
                                  }
                        }
                    >
                        {showRecommendedCourse
                            ? 'Start next course'
                            : 'Review course'}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </ModalWithNoInitialFocus>
    );
};

export default CourseCompleteModal;
